import {Routes} from '@angular/router';
import {AuthComponent} from "./auth/auth.component";




export const routes:any = [
  {path: "", pathMatch: "full", redirectTo: "auth"},
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => {
      return import("./auth/auth.routes").then(
        (m) => m.AUTH_ROUTES
      );
    },
  },
  {path: 'base', loadChildren: () => import('./base/base.routes').then(m => m.BASE_ROUTES)}
];
