import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TuiRootModule, TuiDialogModule, TuiAlertModule, TUI_SANITIZER } from "@taiga-ui/core";
import {Component, inject, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {EventsService} from "./shared/services/events.service";
import {Subject, takeUntil} from "rxjs";
import {HTTPService} from "./shared/services/main.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TuiRootModule, TuiDialogModule, TuiAlertModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
    providers: [{provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}]
})
export class AppComponent implements OnInit{
  title = 'BINDL Админ';


  events = inject(EventsService)
  private toastr = inject(ToastrService);
  private main =  inject(HTTPService);
  private destroy$ = new Subject();


  constructor() {
  }

  ngOnInit(): void {
    this.events.IsErrorChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res && this.events.Error.status) {
          this.toastr.error(this.events.Error.text, "Ошибка!", {
            positionClass: "toast-bottom-center",
            titleClass: 'title-toast'

          });
        } else {
          this.toastr.clear();
        }
      })

    this.events.IsSuccessChange$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res && this.events.Success.status) {
          this.toastr.success(
            this.events.Success.text,
            "Операция выполнена успешно",
            {
              positionClass: "toast-bottom-center",
              titleClass: 'title-toast'
            }
          );
        } else {
          this.toastr.clear();
        }
      })
    this.main.CheckToken();
  }
}
